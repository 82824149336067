* {
  box-sizing: border-box;
}
*,
body {
  margin: 0;
  padding: 0;
}

.MuiInput-underline:after {
  border-color: rgb(85, 130, 55) !important;
}
.MuiFormLabel-root {
  color: rgb(85, 130, 50) !important;
}

img {
  object-fit: contain;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: unset;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}
/* Change Autocomplete styles in default browser */
input:autofill,
textarea:autofill,
select:autofill {
  background-color: transparent;
  border: none;
}
.MuiPickersBasePicker-pickerViewLandscape {
  padding: 0 8px 10px !important;
}

.MuiTableCell-root {
  color: rgb(59, 56, 56);
}

.margin-20 {
  margin: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.form-min-width {
  min-width: 500px;
}

.copyright {
  min-width: max-content;
}

.default-template-container .MuiTextField-root .MuiInputBase-root .MuiInputBase-input.Mui-disabled {
  color: black !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Forms Inputs Responsive */
@media (max-width: 500px) {
  .form-scroll {
    overflow-x: auto;
    width: 100%;
  }
}
