.formEdit-container {
  width: 100%;
  /* height: calc(100vh - 380px); */
}
.title-container,
.content-container,
.url-container {
  padding: 5px 0px;
}
.formEdit-calendar {
  padding: 0px 0px !important;
}
.title-container,
.content-container,
.formEdit-calendar,
.file-container,
.folder-container,
.url-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.title-message,
.content-message,
.url-message {
  flex: 1 !important;
}
.notification-icon,
.content-icon,
.cloud-icon {
  display: flex;
  padding: 5px 15px 0px 0px !important;
}
.folder-icon {
  display: flex;
  padding: 0 25px 0 0;
}
.title-container .title-message .MuiFormControl-root,
.content-container .content-message .MuiFormControl-root,
.url-container .url-message .MuiFormControl-root {
  width: 100%;
}

.formEdit-container .calender-spacing {
  /* width: 40px;
  height: 42px;
  display: flex;
  align-items: flex-end; */
  width: unset;
  position: relative;
  top: 4px;
  margin: 0;
  padding: 10px 15px 0px 0px;
}

.calendar {
  display: flex;
  align-items: center;
  position: relative;
}

.formEdit-container .calendar .calendar-item {
  font-size: 12px !important;
  width: 130px;
  height: 40px;
}

.formEdit-container .calendar .calendar-item .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  position: absolute;
  top: 100%;
  /* left: 10px; */
}

.formEdit-container .calendar-item input {
  padding-left: 10px;
  font-size: 12px !important;
  color: unset;
  padding-bottom: 5px;
}

.title-container .notification-icon .MuiSvgIcon-root,
.content-container .content-icon .MuiSvgIcon-root,
.url-container .cloud-icon .MuiSvgIcon-root {
  color: rgb(85, 130, 50);
}
.portal-create .MuiInputLabel-formControl {
  /* left: 10px; */
  font-size: 12px;
}
.portal-create .MuiInput-underline:before {
  border-bottom: 1px solid rgb(85, 130, 50) !important;
}
.portal-create .MuiSelect-select.Mui-disabled {
  cursor: no-drop !important;
}
.iconForm {
  width: 24px;
  height: 24px;
}
.iconTrash {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  cursor: pointer;
}
.iconReload {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.folder-container {
  padding: 13px 0 5px 0;
  position: relative;
}

.spacingRight {
  white-space: nowrap;
  margin-right: 70px;
  overflow: hidden;
  text-overflow: clip;
}

.max-w-250 {
  max-width: 250px;
}

.spacingRight img {
  margin-right: 20px;
  object-fit: contain;
}
.fileStyle {
  font-size: 12px;
  color: rgb(59, 56, 56);
  width: 300px;
  padding-top: 5px;
}
.templateFileStyle {
  font-size: 12px;
  color: rgb(59, 56, 56);
  width: 403px;
  padding-top: 5px;
  text-overflow: ellipsis;
}
.templateFileDisabledStyle {
  font-size: 12px;
  color: rgb(59, 56, 56);
  width: 403px;
  padding-top: 5px;
  text-overflow: ellipsis;
}
.row_checkbox--send-email {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 20px 0px;
  font-size: 12px;
}

.row_checkbox--send-email .checkbox {
  margin: auto 30px !important;
}

.row_checkbox--send-email .MuiButtonBase-root {
  margin-right: 12px;
}

.formEdit-container .icon--deactive {
  color: rgb(150, 150, 150) !important;
}
.formEdit-container .icon--active {
  color: rgb(85, 130, 50) !important;
  width: 24px;
  height: 24px;
}
.formEdit-container .MuiIconButton-root {
  padding: 0;
  border-radius: 0;
}
.formEdit-container .MuiInputLabel-asterisk {
  color: red;
}
.formEdit-container .wrap {
  padding: 0px 0 !important;
}
.formEdit-container .wrap-icon {
  width: unset;
  position: relative;
  top: 4px;
  margin: 0;
  padding: 10px 15px 0px 0px;
}
.formEdit-container .width120 {
  width: 120px;
}
.formEdit-container .width160 {
  width: 160px;
}
.formEdit-container .width210 {
  width: 210px;
}
.formEdit-container .width300 {
  width: 300px;
}
.formEdit-container .width400 {
  width: 400px;
}
.formEdit-container .width450 {
  width: 450px;
}
.formEdit-container .width540 {
  width: 540px;
}
.formEdit-container .marginRight20 {
  margin-right: 20px;
}
.formEdit-container .wrap-icon svg {
  font-size: 1.5rem !important;
}
.formEdit-calendar .error-message.hidden {
  display: none;
}
.formEdit-calendar .error-message {
  margin: 0 !important;
  color: red;
  margin-left: 38px;
  font-size: 12px;
  position: absolute;
  width: max-content;
  top: 4px !important;
  left: 352px;
}

.formEdit-calendar .custuser-calendar,
.formEdit-calendar .hquser-calendar {
  left: 80px;
  font-size: 10px;
}

.formEdit-calendar .error-message-dtEnd {
  margin: 0 !important;
  color: red;
  margin-left: 38px;
  font-size: 12px;
  position: absolute;
  width: fit-content;
  top: 22px !important;
  left: 300px;
}

.formEdit-container textarea {
  word-wrap: normal;
  word-break: break-word;
}

.formEdit-container .Mui-focused textarea {
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}

.formEdit-container .folder-container_label {
  font-size: 12px;
  color: rgb(85, 130, 50);
  margin-left: -10px;
  position: relative;
  margin-right: 68px;
}

.formEdit-container .folder-container_label .require-star::after {
  color: red;
  content: '*';
  font-size: 20px;
  padding-left: 10px;
  margin-top: -2px;
  background: #fff;
  position: absolute;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.formEdit-container .folder-container_label .require-shinked,
.shinked::after {
  color: red;
  content: '*';
  padding-left: 10px;
  font-size: 16.6px;
  margin-top: 0px;
  position: absolute;
  top: -2px;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.formEdit-container .folder-container_label.shinked {
  font-size: 10px;
  top: 5px;
  position: relative;
}
.formEdit-container .shinked-no-require {
  font-size: 10px;
  top: 5px;
  position: absolute;
}
.formEdit-container .folder-container .MuiIconButton-root {
  padding: 0px 40px;
  border: 1px dashed rgb(85, 130, 50);
  border-radius: 4px;
  margin-left: -2px;
  margin-top: 5px;
}
.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portal-create-content .MuiInput-root {
  font-size: 12px;
}

.formEdit-container .wrap .MuiFormControl-root .MuiInput-formControl .MuiInput-input {
  padding: 5px 0px 2px 10px;
}
.formEdit-container .wrap .MuiFormControl-root .MuiInputBase-multiline {
  padding: 5px 0px 2px 0px;
}
.formEdit-container .calendar-container .MuiInputBase-input {
  padding: 5px 0px 2px 10px;
}

/* .portal-create-content .MuiInput-root input {
  padding: 5px 0px 2px 0px;
} */

.formEdit-container .formEdit-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.formEdit-container .formEdit-input .row_1 {
  width: 180px;
}

.formEdit-container .formEdit-input .row_2 {
  width: 140px;
}

.formEdit-container .formEdit-input .input-spacing {
  width: 40px;
}
.formEdit-container .formEdit-input .wrap .wrap-icon {
  padding: 0;
}
.formEdit-container .formEdit-input .wrap .MuiFormControl-root .MuiInput-formControl {
  width: 100%;
  /* padding-right: 30px; */
}

.formEdit-container .formEdit-input .wrap .MuiFormControl-root {
  margin-right: 44px;
}
.formEdit-container .formEdit-input .MuiFormLabel-root.Mui-disabled {
  color: rgba(150, 150, 150) !important;
}
.formEdit-container .formEdit-input .MuiInput-underline:before {
  border-color: rgba(150, 150, 150) !important;
}

.formEdit-container .wrap label + .MuiInput-formControl {
  margin-top: 18.76px;
}
.portal-create .MuiGrid-item label + .MuiInput-formControl {
  margin-top: 16.38px;
}
.formEdit-container .wrap label + .MuiInputBase-multiline {
  margin-top: 12px;
}

.formEdit-container .testPdfButton {
  width: 150px;
  height: 25px;
  background-color: rgb(85, 130, 50) !important;
  color: rgb(255, 255, 255);
}
/* .formEdit-container .reloadButton {
  width: 50px !important;
  height: 25px;
  background-color: rgb(255, 255, 255) !important;
} */
.formEdit-container .testPdfButton:hover {
  opacity: 0.9;
}
.formEdit-container .testPdfButton:disabled {
  background-color: rgb(150, 150, 150) !important;
  color: rgb(255, 255, 255) !important;
}
.status-label-text {
  color: rgb(85, 130, 50);
  font-size: 10px;
}

/* @media screen and (max-width: 670px) {
  .portal-create {
    padding: 20px;
  }
  .formEdit-container .calendar .calendar-item {
    width: 150px;
    margin-right: 20px;
  }
  .calender-spacing {
    width: 44px;
  }
  .formEdit-input .wrap-mb {
    display: block !important;
  }
}
@media screen and (max-width: 400px) {
  .calender-spacing {
    width: 60px !important;
  }
}
.MuiButton-root {
  min-width: 128px !important;
}
.formEdit-container .formEdit-input.width180 {
  width: 180px;
}
.width140 {
  width: 140px;
}
.row_checkbox--send-email .checkbox {
  width: 120px;
} */
.formEdit-container .MuiButton-root {
  min-width: 10px;
  font-size: 13px;
}
.label-info-container {
  display: flex;
  align-items: center;
}
