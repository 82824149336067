.maintain-wrapper {
  width: 100%;
  display: block;
  /* overflow: auto; */
}

.maintain-container {
  margin: 0 20px 20px 15px;
}

/* .toolbar {
  height: 60px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.iconPreview,
.iconBuild,
.iconCalendar,
.iconArrow {
  color: rgb(85, 130, 50);
}
.iconArrow {
  display: inline-flex;
  align-items: center;
}
/* .toolbar-text {
  font-size: 10px;
  color: rgb(166, 166, 166);
} */

.selectTextLabel {
  font-size: 12px;
}

.sortedContainer {
  position: relative;
  display: flex;
  height: 40px;
  align-items: 'center';
  justify-content: space-between;
}

/* .search-item {
  width: 100%;
  height: 100%;
} */

/* .redirect-item {
  display: flex;
  align-items: center;
} */

.filter-item {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
}

.sorted-select {
  width: 90%;
  height: 100%;
  display: flex;
}

.select-container {
  width: 100%;
  height: 100%;
}
.sorted-container {
  width: 100%;
  height: 75%;
}
/* .calendar-item {
  width: 55%;
  height: 70%;
} */
.preview-filter .calendar-container,
.maintain-container .calendar-container {
  width: 105px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
.sorted-item {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.sorted-text {
  font-size: 8px;
  color: grey;
}
.preview-header,
.preview-filter,
.preview-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 28px;
}
.preview-header {
  padding: 0px 20px 0px 20px;
}

.MuiDialogTitle-root {
  padding-bottom: 0 !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
  width: 82.431%;
  height: 100%;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: rgb(85, 130, 50) !important;
}

.MuiPickersCalendarHeader-dayLabel,
.MuiPaper-root .MuiSvgIcon-root {
  color: rgb(85, 130, 50);
}

.table-container .MuiTableCell-root {
  border-bottom: none;
}
.sortedIcon-container {
  display: flex;
  flex-direction: column;
}

.startDate-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.date-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 42px;
  font-size: 20px;
  line-height: 0px;
  color: rgb(85, 130, 50) !important;
}

.portal-preview {
  padding: 0px 24px !important;
}

.maintain-wrapper .createContainer .icon-redirect {
  margin-right: 0;
  margin-left: 0;
}

/* .searchWrapper {
  height: 25px;
  display: flex;
  margin-bottom: 5px;
  padding-right: 50px;
} */

/* .redirectContainer {
  width: fit-content;
  height: 100%;
} */

/* .searchContainer {
  height: 100%;
} */

/* .filterWrapper {
  width: 60%;
  height: 100%;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
} */

/* .filterText {
  width: 9%;
  display: flex;
  align-items: center;
} */

/* .filterContainer {
  width: 80%;
  display: flex;
  height: 25px;
} */

/* .select-item,
.calendar-item {
  width: 95px;
  height: 100%;
  margin-right: 10px;
} */

/* .maintain-wrapper .calendar-item input {
  font-size: 12px;
  color: black;
} */

.overflow-text {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  /* line-clamp: 3; */
}

/* @media (max-width: 1780px) {
  .filterText {
    width: 9%;
  }
} */

@media (max-width: 1570px) {
  /* .maintain-container .filterWrapper .filterText {
    display: flex;
    min-width: 76px;
    margin-right: 10px;
  } */
  .date-icon {
    font-size: 10px;
  }
}
