#modal__confirm .MuiPaper-root {
  font-size: 16px;
  border-radius: 2px;
  border: 2px solid rgb(85, 130, 50);
  /* width: 403px; */
  width: 500px;
  height: 170px;
}

#modal__confirm .dialog__message-content {
  display: flex;
  align-items: flex-start;
  margin: 20px;
  padding: 0px 10px;
  color: rgb(85, 130, 50);
  font-size: 12px;
  overflow-y: hidden;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 15px;
}

#modal__confirm .dialog__message-content p {
  color: rgb(85, 130, 50);
  /* font-size: 14px; */
}
#modal__confirm .dialog__message-title {
  margin: 0;
  padding: 0;
  background: rgb(85, 130, 50);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 15px;
}
#modal__confirm .dialog__message-title h2 {
  /* font-size: 18px; */
  font-size: 13px;
  margin: 5px 0;
}

#modal__confirm .MuiDialogActions-root button {
  width: 128px;
  height: 28px;
  margin: 0 10px;
}
#modal__confirm .dialog__message-action {
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal__confirm .button-submit {
  background-color: rgb(85, 130, 55) !important;
  border-color: 1px solid rgb(85, 130, 55) !important;
  font-size: 13px;
}

#modal__confirm .button-cancel {
  color: rgb(59, 56, 56) !important;
  border: 1px solid rgb(85, 130, 50) !important;
  padding: 5px;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
}

#modal__confirm .MuiDialog-root .MuiDialog-paperWidthFalse {
  max-width: 350px !important;
}

#confirm-dialog-title {
  color: rgb(255, 255, 255);
  background-color: rgb(85, 130, 55) !important;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

#modal__confirm .MuiDialog-container .MuiDialog-paper {
  margin: 0;
  padding: 0;
}

#modal__confirm .wrapper__opacity {
  padding: 0;
  margin: 0;
}

.message__pre-text {
  text-align: left !important;
  font-size: 12px !important;
}

.modal-confirm-overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 399px;
  float: left;
}

.display-inline {
  float: left;
}

.message-container {
  width: 100%;
}
.message-line2 {
  display: inline-flex;
}
.marginRight0 {
  margin-right: 0px !important;
}

.flex {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.distribution .dialog__message-content {
  margin-right: 0px !important;
}

.reissue .dialog__message-content {
  margin-right: 5px !important;
}

#modal__confirm .message__pre-text {
  font-size: 12px !important;
}

#modal__confirm .MuiButton-label {
  font-size: 13px !important;
}

.justifyEnd {
  justify-content: flex-end !important;
}
