.wrapper__progress-dialog {
  position: fixed;
  left: 50%;
  top: 50% !important;
  color: #fff;
  width: 250px !important;
  margin: -30px 0 0 -125px;
  border-radius: 5px;
  padding: 20px 30px;
  background-color: #548135 !important;
  z-index: 5;
  font-size: 14px;
}

.wrapper__progress-dialog label {
  font-weight: normal;
}

.MuiLinearProgress-root {
  position: relative;
  margin-top: 5px !important;
  height: 3px;
}

.MuiLinearProgress-bar {
  background-color: #548135 !important;
  height: 5px !important;
  overflow: hidden;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #fff !important;
  animation: MuiLinearProgress-keyframes-barColorPrimary 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite !important;
}
.MuiLinearProgress-bar2Indeterminate .MuiLinearProgress-barColorPrimary {
  background-color: #fff !important;
  display: none;
}

@keyframes MuiLinearProgress-keyframes-barColorPrimary {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
