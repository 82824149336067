.makeStyles-titleHead-11 {
  font-size: 11px !important;
}
.MuiButton-label {
  font-size: 13px;
}
.portal-createWrapper .space {
  padding: 0px 0px !important;
}
/* .portal-create .MuiInputLabel-formControl {
  top: 5px !important;
} */
.makeStyles-root-12 .MuiTextField-root {
  min-height: 40px;
}
.makeStyles-root-104 .MuiTextField-root {
  height: 40px !important;
}
/* .makeStyles-root-69 {
  min-width: 105px !important;
} */
.makeStyles-tableRow-35 {
  /* background-color: #ffffff !important; */
}
.table-head {
  background-color: #f2f2f2 !important;
}
.table-row {
  background-color: #f5faeb !important;
}

.portal-wrapper.MuiTableContainer-root {
  overflow-x: hidden;
}

@media (max-width: 1365px) {
  .portal-wrapper .MuiTableContainer-root {
    overflow-x: scroll;
  }
}
