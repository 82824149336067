.nav-main {
  box-sizing: content-box;
  width: 100%;
  border-bottom: 2px solid #e6e7e6;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit;
}
.nav-wrapper {
  display: flex;
  width: calc(100% - 30px);
  margin: 0 15px;
  color: rgb(85, 130, 50);
  flex-wrap: wrap;
  height: 60px;
}

.nav-title {
  flex: 2;
  display: flex;
  align-items: center;
}
.nav-description {
  font-weight: 500;
  font-size: 1.1rem;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav-item {
  padding: 10px;
  pointer-events: auto;
  cursor: pointer;
}
.nav-icon {
  padding: 0 10px;
}
.nav-title .nav-item,
.nav-datetime,
.nav-userinfo {
  min-width: 150px;
}
.nav-pagename {
  font-size: 13px;
  padding: 10px;
  width: max-content;
}
.nav-datetime,
.nav-userinfo {
  font-size: 12px;
}
.nav-datetime{
  cursor: default;
}
.nav-userinfo {
  padding-right: 0;
  min-width: 100px;
}
.nav-userinfo a {
  text-decoration: none;
  color: rgb(85, 130, 50) !important;
}
.nav-dropdown {
  padding-left: 0;
}
.nav-logout {
  margin-top: 5px;
  pointer-events: auto;
  cursor: pointer;
}
.logout-icon {
  width: 26px;
  height: 26px;
}
.avatar-icon {
  width: 25px;
  height: 25px;
  padding-left: 3px;
  margin: 1px 0px 0px 0px;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgb(85, 130, 50);
}

.nav-wrapper .icon__toggle-show {
  display: block;
}
.nav-wrapper .icon__toggle-hide {
  display: none;
}
/* Responsive Navbar */

@media (max-width: 1280px) {
  .nav-description {
    flex: 6;
  }
  .nav-title {
    flex: 4;
  }
  .nav-pagename {
    font-size: 13px;
  }
}

@media (max-width: 959px) {
  .nav-title .nav-item {
    display: none;
  }
  .nav-userinfo {
    display: none;
  }
}

@media (max-width: 650px) {
  .nav-description .nav-datetime {
    display: none;
  }
  .nav-description .nav-icon {
    display: none;
  }
}
