.toolbar {
  height: 60px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
}
.toolbar-no-height {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
}
.toolbar-no-height-padding-top {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
  padding-top: 15px;
}

/* customize select with min-width  max content*/
.select-download {
  min-width: max-content;
}
.select-download .wrapper_select_input .MuiOutlinedInput-root {
  min-width: max-content;
}
.toolbar-no-height-padding-bottom {
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1050px;
  padding-bottom: 15px;
}
.toolbar-small {
  min-width: 900px;
}

.actionContainer {
  width: 80%;
  height: 25px;
  display: flex;
  align-items: center;
  /* margin-bottom: 5px; */
}

.csv-container {
  width: 20%;
  height: 25px;
  margin-bottom: 5px;
  /* align-items: center;
  margin-bottom: 5px; */
}

.csv-style {
  margin-right: 30px;
}

.searchContainer {
  height: 100%;
  width: 270px;
  margin-right: 50px;
}

.searchSmallContainer {
  height: 100%;
  width: 150px;
  margin-right: 50px;
}
.searchLeftContainer {
  height: 100%;
  width: 270px;
  margin-left: 50px;
}

.search-item {
  width: 100%;
  height: 100%;
}

.icon-redirect {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.createContainer .icon-redirect {
  margin: 0 40px 0 10px;
}

.icon-action {
  width: 16px;
  height: 18px;
  cursor: pointer;
}
.filter-icon-table-portal {
  margin-left: 50px;
}

.previewContainer,
.createContainer,
.filterIconContainer,
.expireDateContainer,
.filterWeekContainer,
.turnBackIconContainer,
.filterIconOnlyContainer,
.timeLabelContainer {
  width: fit-content;
  height: 100%;
}

.previewContainer img {
  margin: 0 10px 0 15px;
}

.icon-filter {
  width: 16px;
  height: 18px;
  margin-right: 15px;
  /* margin-left: 5px; */
}

.icon-filter-only {
  width: 16px;
  height: 18px;
}

/* .filterWeekContainer {
  margin-left: 20px;
} */

.filterIconContainer,
.filterWeekContainer,
.downloadIconContainer,
.checkboxContainer,
.expireDateContainer,
.timeLabelContainer {
  display: flex;
  align-items: center;
}

.turnBackIconContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.turnBackIconDownloadStatusContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.filterIconOnlyContainer {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.toolbar-text {
  font-size: 12px;
  color: rgb(166, 166, 166);
  margin-right: 12px;
}

.timelablel-text {
  font-size: 10px;
  color: rgb(166, 166, 166);
  margin-right: 10px;
  margin-left: 10px;
}

.select-item {
  height: 100%;
  width: 90px;
  margin-right: 10px;
}

.filterContainer {
  /* width: 80%; */
  display: flex;
  height: 25px;
}

.year-month-calendar-container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
}

.dt-week-calendar-container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
  margin-right: 15px;
}

.week-calendar-item {
  /* margin-left: 2px; */
  margin-right: 15px;
}
.complianceCalendarFilter {
  display: flex;
  height: 25px;
  margin-right: 20px;
}

.downloadIconContainer {
  margin-right: 15px;
  width: fit-content;
  height: 100%;
}
.checkboxContainer {
  margin-right: 15px;
}
.calendar-item {
  width: 95px;
  height: 100%;
  margin-right: 10px;
}

.icon-turnback {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.calendar-item input {
  font-size: 12px;
  color: black;
}

.btn-download {
  height: 25px;
  display: flex;
  position: relative;
  margin-bottom: 15px;
  color: white;
  font-size: 13px;
  min-width: 170px;
  justify-content: flex-end;
}

.btn-text {
  color: white;
  text-decoration: none;
  font-size: 13px;
}

.btn-download .MuiButton-root {
  min-width: 170px !important;
}
.toolbar .calendar-item .MuiInputBase-root .MuiInputBase-input {
  font-size: 11px !important;
}

.toolbar .MuiSelect-select:focus {
  background-color: transparent;
}
.toolbar-no-height .MuiSelect-select:focus {
  background-color: transparent;
}
.approvalContainer,
.hqConfirmContainer {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.hqConfirm-label,
.apporval-label,
.filterWeek-label {
  width: max-content;
}

.compliance__wrapper .searchContainer {
  margin-right: 11px;
}

.arrow-back-icon {
  color: rgb(85, 130, 50);
}

.widthUnset {
  width: unset;
}

@media (max-width: 1570px) {
  .maintain-container .filterIconContainer .toolbar-text,
  .expireDateContainer .toolbar-text,
  .timeLabelContainer .timelablel-text {
    min-width: 50px;
  }
}

/* Head-quarter responsive */
@media (max-width: 992px) {
  .hquser__wrapper .csv-container {
    min-width: 170px !important;
  }
  .hquser__wrapper .actionContainer {
    min-width: 606px !important;
  }
}

/* Branch responsive */
@media (max-width: 1050px) {
  .branch__wrapper .csv-container {
    min-width: 170px !important;
  }
  .branch__wrapper .actionContainer {
    min-width: 645px !important;
  }
}

/* Customer responsive */
@media (max-width: 1026px) {
  .customer__wrapper .csv-container {
    min-width: 170px !important;
  }
  .customer__wrapper .actionContainer {
    min-width: 645px !important;
  }
}

/* Customer User responsive */
@media (max-width: 988px) {
  .custuser__wrapper .csv-container {
    min-width: 170px !important;
  }
  .custuser__wrapper .actionContainer {
    min-width: 602px !important;
  }
}
