.hq-password {
  display: flex;
  width: 100%;
  height: 40px;
}

.hq-password-header {
  display: flex;
  width: 100%;
  height: 30px;

}

.input-password-custom .hq-password:nth-of-type(2){
  margin-top: -4px;
}
.input-password-custom .hq-password:nth-of-type(3){
  margin-bottom: 4px;
  margin-top: 0;
}
.input-password-custom .hq-password-header{
  display: none;
}
.input-password-custom {
  margin-top: -20px;
}


.password-header-disable{
  display: none;
}
.background-password-form .hq-password-header{
  height: 26px;
}
.hq-password-icon {
  color: rgb(150, 150, 150) !important;
  padding: 0px 15px 0px 0px;
}
.hq-password-title {
  color: rgb(85, 130, 50);
  font-size: 12px;
  padding-top: 5px;
  /* margin-top:-2px */
}

.hq-password input {
  width: 450px !important;
}

.hq-password .MuiButtonBase-root {
  color: rgb(85, 130, 50);
}

.hq-password .MuiFormControl-root {
  height: 40px;
}

.hq-password .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 0;
}

.hq-password .MuiGrid-spacing-xs-1 {
  width: 100%;
  margin: 0;
}

.hq-password .MuiFormLabel-root {
  padding-left: 0 !important;
}

.hq-password .changePass-label div {
  position: absolute;
  width: max-content;
  top: -2px;
  font-size: 12px;
}

.hq-password .MuiGrid-root,
.hq-password .MuiInputBase-root {
  width: 450px;
}

.hq-password .MuiInputBase-input {
  padding: 5px 0 2px 10px;
}

.hq-password button {
  padding-right: 30px !important;
}

.hq-password .changePass-labelEnd {
  font-size: 20px !important;
  font-weight: 400;
  line-height: 1;
  left: 80px;
}

.hq-password .content-input {
  height: 40px;
}

.hq-password .changePass-label .error-message {
  width: max-content;
  position: absolute;
  top: -3px;
  left: 80px;
  font-size: 12px;
  color: #f44336;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.left140 {
  left: 140px !important;
}
