.reissue-wrapper {
  padding: 16px 20px 0 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.userInfo {
  padding: 0 0 20px 40px;
}

.reissue-wrapper .icon {
  width: 24px !important;
  height: 24px !important;
  color: rgb(150, 150, 150) !important;
}

.d-flex {
  display: flex;
}

.text-green {
  color: rgb(85, 130, 50) !important;
}

.fontSize-10 {
  font-size: 10px;
}

.fontSize-12 {
  font-size: 12px;
}

.fontSize-13 {
  font-size: 13px;
}

.margin-r15 {
  margin-right: 15px;
}

.margin-l10 {
  margin-left: 10px;
}

.margin-b10 {
  margin-bottom: 10px;
}
