.portal-createWrapper {
  width: 100%;
  min-height: 80vh;
}
.portal-createWrapper .button {
  width: 128px;
  height: 28px;
  color: rgb(85, 130, 50);
  border-color: rgb(85, 130, 50) !important;
  margin: 0 20px 0 0px !important;
  /* padding: 0 !important; */
}
.portal-createWrapper .button.clear-button {
  color: rgb(59, 56, 56);
}
.button:hover {
  opacity: 0.9;
}

.portal-createWrapper .submit-button {
  background-color: rgb(85, 130, 50) !important;
  color: #fff !important;
}

.portal-createWrapper .submit-button:disabled {
  background-color: rgb(187, 187, 187) !important;
}

.buttonSpace {
  margin-top: 50px;
}
form {
  height: 100%;
}

.portal-create {
  height: 100%;
  padding: 20px 40px;
  max-width: 1200px;
}
.portal-create-content {
  height: 100%;
}
.portal-create .MuiTextField-root .MuiInputBase-root .MuiInputBase-input.Mui-disabled {
  color: rgb(150, 150, 150);
}

.portal-create .calendar .calendar-item {
  margin-right: 21px;
}

.allergen-error-message {
  margin: 0 !important;
  color: red;
  margin-left: 38px;
  font-size: 10px;
  position: absolute;
  width: fit-content;
  top: 1px !important;
  left: 352px;
}
