.reception-data__maintain-wrapper {
  width: 100%;
  display: block;
  /* overflow: auto; */
}

/* .reception-data__maintain-wrapper .reception-data__container {
  width: 100%;
} */

.reception-data__wrapper .toolbar-no-height {
  height: 60px;
  padding-top: 0;
}
