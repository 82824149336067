.footer .copyright {
  color: #000;
  padding: 15px;
  text-align:center;
  margin: 0;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}
.footer {
  margin-top: 10px;
}
