/* .custuser__wrapper .calendar-item .MuiInputBase-root .MuiInputBase-input {
  margin-top: 2px;
  font-size: 12px;
} */

/* .custuser__wrapper .wrapper-search {
  width: fit-content;
  height: 25px;
  display: flex;
  margin-bottom: 15px;
} */
/* .custuser__wrapper .wrapper-filter {
  width: 26%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  padding-left: 50px;
} */
/* .custuser__wrapper .filter-container {
  height: 25px;
} */

/* .custuser__wrapper .wrapper-filter .filter-text {
  display: flex;
  align-items: center;
  margin: auto 10px auto 0px;
} */

/* .custuser__wrapper .filter-container .MuiInputAdornment-positionEnd {
  margin: unset;
}
.custuser__wrapper .filter-container .calendar-item .MuiFormControl-root {
  position: relative;
}
.custuser__wrapper .filter-container .calendar-item .MuiInputBase-root {
  position: absolute;
}
.custuser__wrapper .filter-container .calendar-item .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  margin: 0 10px;
  top: 26px;
  position: absolute;
} */
/* 
@media screen and (max-width: 768px) {
  .custuser__wrapper .custuser__container .toolbar-text {
    display: none;
  }
} */

/* .custuser__wrapper .btn-download {
  width: 57%;
  height: 25px;
  display: flex;
  position: relative;
  margin-bottom: 15px;
  color: white;
  min-width: 170px;
  justify-content: flex-end;
}
.btn-text {
  color: white;
  text-decoration: none;
  font-size: 12px;
}
.MuiButton-root {
  min-width: 170px !important;
} */

@media (max-width: 1290px) {
  .custuser__wrapper .table-scroll {
    overflow-x: auto;
    width: 100%;
  }
}
