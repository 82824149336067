.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  overflow-y: hidden !important;
}

.main-panel {
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main-panel.mob_show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.side-bar.mob_show {
  display: none;
}

.hiddenOnScroll {
  z-index: 5;
  /* overflow: auto; */
  overflow-x: hidden !important;
}

.company-logo {
  cursor: pointer;
}

@media screen and (max-width: 959px) {
  .side-bar {
    display: none;
  }
}
