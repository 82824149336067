.sidebar-item-container {
  width: 100%;
  height: 23px;
  display: flex;
  background-color: rgb(85, 130, 50);
  margin: 1px 0;
}

.sidebar-item-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
}
.sidebar-item-container a {
  text-decoration: none;
}

.menu-icon,
.menu-text {
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  color: rgb(250, 250, 250);
}

.menu-text {
  margin-left: 10px;
}
