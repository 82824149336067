.sidebar-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.side-bar {
  display: block;
  background-color: rgb(245, 250, 235);
  height: 100vh;
  width: 200px;
}
.menu-container::-webkit-scrollbar {
  display: none;
}
.wrapper__side-bar {
  position: relative;
  overflow: auto;
}
.wrapper__side-bar::-webkit-scrollbar {
  display: none;
}
.company-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-header {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(85, 130, 50);
}

.header-text {
  width: 90%;
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.menu-container {
  margin-top: 10px;
  overflow: scroll;
}

.sidebar-icon {
  cursor: pointer;
  width: 14px;
  height: 20px;
}

.sidebar-arrowIcon {
  width: 14px;
  height: 14px;
}

.menu-wrapper {
  margin: 3px 5px 3px 0px;
}

.menu-content {
  width: 100%;
  padding-left: 35px;
}

.active-link {
  color: rgb(203, 47, 47) !important;
}

.closeMenu-container img{
  cursor: pointer;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.hide-sideBar {
  display: none;
}
.side-bar .sidebar-logo {
  width: 100%;
  padding: 4px;
  border: 1px solid rgb(85, 130, 50);
  background: #fff;
}

.sidebar-logo {
  width: 100%;
}

.wrapper__side-bar .text-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  text-decoration: none;
}

.wrapper__side-bar .link-name {
  color: rgb(85, 130, 50);
  font-size: 12px;
  cursor: pointer;
}
.wrapper__side-bar .link-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wrapper__side-bar .text-link:last-child {
  margin-bottom: 0px;
}

.wrapper__side-bar .active-link .link-name {
  color: rgb(203, 47, 47) !important;
}