.myPage_container {
  width: 100%;
}

.myPage_wrapper {
  padding: 16px 20px 0 20px;
}

.userInfo_form_container {
  height: 100%;
  padding: 5px 40px 10px 40px;
  max-width: 1200px;
}

.userInfo_header_text,
.change_userPass_header_text {
  color: rgb(85, 130, 50);
  padding: 5px 0px 5px 15px;
  font-size: 13px;
  border-bottom: none;
}

.userInfo_header,
.change_userPass_header {
  border-bottom: hidden;
  background-color: rgb(245, 250, 235);
}

.nm-user-container {
  display: flex;
}

.userInfo_form_container .changepass-container {
  padding: 0;
}

.changepass-container .wrap-lock-icon {
  padding: 0 15px 0 0 !important;
}

.changepass-container .MuiInputBase-input {
  padding: 5px 0px 2px 10px;
  font-size: 13px;
}

.userInfo_header_text_wrapper,
.change_userPass_header_text_wrapper {
  min-width: max-content;
}

.userInfo_form_container .MuiGrid-item {
  /* padding: 10px 15px 0px 0px !important; */
}

.userInfo_form_container .content {
  height: 40px;
}

.myPage_wrapper .nm-user-container .MuiCheckbox-colorSecondary.Mui-disabled,
.myPage_wrapper .nm-user-container .MuiCheckbox-colorSecondary.Mui-checked,
.myPage_wrapper .nm-user-container .custom-checkbox .Mui-checked .MuiSvgIcon-root {
  color: grey !important;
  padding-left: unset;
}

.submit-button {
  width: 128px;
  height: 28px;
}
.clear-button {
  width: 128px;
  height: 28px;
}
