.portal-createWrapper {
  width: 100%;
  min-height: 80vh;
}
.portal-createWrapper .textInput {
  width: 66.6%;
  outline-color: rgb(85, 130, 50);
}

.portal-createWrapper .textDrop {
  width: 160px;
}
.portal-createWrapper .space {
  padding: 5px 0px;
}
.portal-createWrapper .text {
  width: 300px;
}
.portal-createWrapper .date {
  padding-left: 30px;
}
.portal-createWrapper .iconCalender {
  font-size: 10px;
}
.portal-createWrapper .button {
  width: 128px;
  height: 28px;
  color: rgb(85, 130, 50);
  border-color: rgb(85, 130, 50) !important;
  margin: 0 20px 0 0 !important;
  /* padding: 0 !important; */
}
.portal-createWrapper .button.clear-button {
  color: rgb(59, 56, 56);
}
.button:hover {
  opacity: 0.9;
}

.portal-createWrapper .submit-button {
  background-color: rgb(85, 130, 50) !important;
  color: #fff !important;
}

.portal-createWrapper .submit-button:disabled {
  background-color: rgb(187, 187, 187) !important;
}

.id-user-container {
  display: flex;
  align-items: flex-end;
}

.buttonSpace {
  margin-top: 50px;
}
.listitem {
  padding: 0px 40px;
}

form {
  height: 100%;
}
.portal-create {
  height: 100%;
  /* padding: 20px 400px 20px 60px; */
  padding: 20px 40px;
  max-width: 1200px;
}
.portal-create .MuiSelect-select {
  padding-left: 10px;
  font-size: 12px;
  padding-bottom: 2px;
  padding-top: 5px;
  color: rgb(59, 56, 56);
}
.portal-create .textDrop .MuiSvgIcon-root.MuiSelect-icon {
  cursor: pointer;
  font-size: 32px;
  transform: scaleY(1.5);
  color: rgb(85, 130, 50);
}
button.MuiButtonBase-root.MuiIconButton-root.icon-visibility.MuiIconButton-edgeEnd {
  padding: 0 5px !important;
}
.portal-create .Mui-disabled .MuiSvgIcon-root.MuiSelect-icon {
  color: unset;
}
.portal-create-content {
  height: 100%;
}
.portal-create .icon--check {
  width: 39px;
  position: relative;
}
.portal-create .icon--check .MuiSvgIcon-root {
  color: rgb(150, 150, 150);
  position: absolute;
  top: -20px;
}

.portal-create .MuiTextField-root .MuiInputBase-root .MuiInputBase-input.Mui-disabled {
  color: rgb(150, 150, 150);
}

.portal-create .calendar .calendar-item {
  margin-right: 21px;
}

#KB_MESSAGE__select-label {
  top: 0 !important;
  left: 0 !important;
  position: absolute !important;
  transform: translate(10px, 24px) scale(1) !important;
}

.hq-password {
  display: flex;
  width: 100%;
  height: 40px;
}

.hq-password-header {
  display: flex;
  width: 100%;
  height: 30px;
}

.hq-password-icon {
  color: rgb(150, 150, 150) !important;
  padding: 0px 15px 0px 0px;
}

.hq-password-title {
  color: rgb(85, 130, 50);
  font-size: 12px;
  padding-top: 5px;
}

.hq-password input {
  width: 450px !important;
}

.hq-password .MuiButtonBase-root {
  color: rgb(85, 130, 50);
}

.hq-password .MuiFormControl-root {
  height: 40px;
}

.hq-password .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 0;
}

.hq-password .MuiGrid-spacing-xs-1 {
  width: 100%;
  margin: 0;
}

.hq-password .MuiFormLabel-root {
  padding-left: 0 !important;
}

.hq-password .changePass-label div {
  position: absolute;
  width: max-content;
  top: -2px;
  font-size: 12px;
}

.hq-password .MuiGrid-root,
.hq-password .MuiInputBase-root {
  width: 450px;
}

.hq-password .MuiInputBase-input {
  padding: 5px 0 2px 10px;
}

.hq-password button {
  padding-right: 30px !important;
}

.hq-password .changePass-labelEnd {
  font-size: 20px !important;
  font-weight: 400;
  line-height: 1;
  left: 80px;
  top: -5px;
}

.hq-password .content-input {
  height: 40px;
}

.error-message {
  width: max-content;
  position: absolute;
  top: 18px;
  left: 500px;
  font-size: 12px;
  color: #f44336;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.left125 {
  left: 125px !important;
}

.left70 {
  left: 70px !important;
}

.left170 {
  /* left: 170px !important; */
}

.custom-password-headquarter .hq-password {
  margin-top: -2px;
}
.custom-password-headquarter .hq-password:last-child {
  margin-top: 0;
  margin-bottom: 2px;
}
/* @media (max-width: 1200px) and (min-width: 959px) {
  .portal-create {
    padding: 20px 200px 20px 60px;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .portal-create {
    padding: 20px 100px 20px 40px;
  }
}

@media (max-width: 599px) {
  .portal-create {
    padding: 20px 50px 20px 30px;
  }
  .portal-createWrapper .buttonSpace {
    display: flex;
    justify-content: space-around;
  }
  .portal-createWrapper .button {
    width: 120px;
    margin: 0 auto;
  }
  .MuiButton-root {
    min-width: 128px !important;
  }
} */
