#dialog__message .MuiPaper-root {
    font-size: 14px;
    /* min-width: 350px; */
    width: 500px;
    height: 170px;
    border-radius: 2px;
    border: 2px solid rgb(85, 130, 50);
  }
  #dialog__message .dialog__message-content {
    display: flex;
    align-items: flex-start;
    margin: 20px;
    padding: 0px 10px;
    color: rgb(85, 130, 50);
    font-size: 12px;
    overflow-y: hidden;
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 15px;
  }
  
  #dialog__message .dialog__message-content p {
    color: rgb(85, 130, 50);
    font-size: 13px;
  }
  #dialog__message .dialog__message-title {
    margin: 0;
    padding: 0;
    background: rgb(85, 130, 50);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 15px;
  }
  #dialog__message .dialog__message-title h2 {
    font-size: 13px;
    margin: 5px 0;
  }
  
  #dialog__message .MuiDialogActions-root button {
    width: 150px;
    height: 25px;
    background: rgb(85, 130, 50);
    color: #fff;
    font-size: 13px;
  }
  #dialog__message .dialog__message-action {
    padding: 15px;
    margin: 0 10px;
  }
  /* Customize confirm dialog pop-up width */
  #dialog__message .MuiDialog-root .MuiDialog-paperWidthFalse {
    max-width: 445px;
  }
  
  @media screen and (min-width: 450px) {
    #dialog__message .MuiDialog-root .MuiDialog-paperWidthFalse {
      width: 445px;
    }
  }
  