.changepass-container {
  /* min-height: 80vh; */
  padding: 60px 0px 20px 60px;
}

.content {
  /* min-height: 90px; */
}

.changepass-container .error-message {
  color: red;
  margin-left: 0;
  font-size: 13px;
}

.clear-button {
  background-color: white !important;
}

.button {
  width: 130px;
  color: #fff;
  border-color: rgb(85, 130, 50) !important;
  margin-right: 20px !important;
  /* margin-top: 30px !important; */
}

.button:hover {
  opacity: 0.9;
}

.submit-button:disabled {
  background-color: rgb(187, 187, 187) !important;
}

.submit-button {
  background-color: rgb(85, 130, 50) !important;
  color: #fff !important;
}

.btn-container {
  margin-top: 70px;
  font-size: 13px;
}

.changepass-container .MuiSvgIcon-root {
  color: rgb(150, 150, 150) !important;
}

.changepass-container .icon-visibility svg {
  color: rgb(85, 130, 50) !important;
}

.changepass-container .content .MuiInputBase-root.MuiInput-root {
  min-width: 450px;
}

.changepass-container .MuiFormControl-root {
  height: 100%;
}

.changepass-container .MuiGrid-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.changepass-container .MuiGrid-item {
  padding: 0 !important;
}

.changepass-container .MuiGrid-item label + .MuiInput-formControl {
  margin-top: 0px;
}

.change_userPass_infor {
  margin-top: 30px;
}

.changepass-container .content .MuiInputLabel-shrink {
  transform-origin: 40px 40px;
}

.change-password-container .MuiFormControl-root {
  height: 40px;
}
/* custom class with title input password label */
.customChangePassword.MuiFormLabel-root {
  font-size: 13px !important;
}
.customChangePassword.MuiInputLabel-shrink,
.portal-create .customChangePassword.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.769) !important;
  transform-origin: 40px 40px;
}
.changepass-container .content .customChangePassword {
  transform-origin: 40px 40px;
}

@media (max-width: 600px) {
  .changepass-container {
    /* padding: 60px 0px 20px 40px; */
    padding: 40px 10px;
  }
  .changepass-container .error-message {
    margin-left: 10px;
  }
  .changepass-container .MuiInputBase-root.MuiInput-root {
    min-width: 300px;
  }
}
