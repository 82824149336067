.branch__maintain-wrapper {
  width: 100%;
  display: block;
  /* overflow: auto; */
}

.branch__maintain-wrapper .branch__container {
  width: 100%;
}
/* .branch__maintain-wrapper .calendar-item .MuiInputBase-root .MuiInputBase-input {
  padding: 0 10px !important; 
  margin-top: 2px;
  font-size: 12px;
} */

/* .branch__maintain-wrapper .wrapper-search {
  width: fit-content;
  height: 25px;
  display: flex;
  margin-bottom: 15px;
} */
/* .branch__maintain-wrapper .wrapper-filter {
  width: 45%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
} */
/* .branch__maintain-wrapper .filter-container {
  height: 25px;
  display: flex;
  justify-content: flex-start;
} */

/* .branch__maintain-wrapper .wrapper-filter .filter-text {
  display: flex;
  align-items: center;
  margin: auto 10px auto 30px;
  padding-left: 20px;
  min-width: 30px;
} */

/* .branch__maintain-wrapper .filter-container .MuiInputAdornment-positionEnd {
  margin: unset;
}
.branch__maintain-wrapper .filter-container .calendar-item .MuiFormControl-root {
  position: relative;
}
.branch__maintain-wrapper .filter-container .calendar-item .MuiInputBase-root {
  position: absolute;
}
.branch__maintain-wrapper .filter-container .calendar-item .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  margin: 0 10px;
  top: 26px;
  position: absolute;
} */

/* .branch__maintain-wrapper .select-item .select-container .MuiOutlinedInput-input,
.branch__maintain-wrapper .sorted-select .sorted-container .MuiOutlinedInput-input {
  padding-top: 0px;
  padding-bottom: 0px;
} */

/* .branch__container .search-item .MuiFormControl-root .MuiInputBase-root input {
  min-width: 222px;
} */

/* .branch__container .wrapper-filter .filter-text .toolbar-text {
  padding-right: 0;
} */
/* @media screen and (max-width: 768px) {
  .branch__maintain-wrapper .branch__container .toolbar-text {
    display: none;
  }
} */

/* .branch__maintain-wrapper .btn-download {
  width: 34%;
  height: 25px;
  display: flex;
  position: relative;
  margin-bottom: 15px;
  color: white;
  min-width: 100px;
  justify-content: flex-end;
}
.btn-text {
  color: white;
  text-decoration: none;
  font-size: 12px;
} */
/* .redirect-item-branch {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
