.customer__maintain-wrapper {
  width: 100%;
  display: block;
  overflow-x: hidden;
}
/* custom css for toolbar filter title icon */
@media (max-width: 1300px)
{
.toolbarTitleNotTextWrap .actionContainer{
  white-space: nowrap;
  padding-right: 5px;
}

.toolbarTitleNotTextWrap{
  min-width: max-content;
}}
.customer__maintain-wrapper .customer__container {
  width: 100%;
}
/* .customer__maintain-wrapper .calendar-item .MuiInputBase-root .MuiInputBase-input {
  margin-top: 2px;
  font-size: 12px;
} */

/* .customer__maintain-wrapper .wrapper-search {
  width: fit-content;
  height: 25px;
  display: flex;
  margin-bottom: 15px;
} */
/* .customer__maintain-wrapper .wrapper-filter {
  width: 45%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
} */
/* .customer__maintain-wrapper .filter-container {
  height: 25px;
  display: flex;
  justify-content: flex-start;
} */

/* .customer__maintain-wrapper .wrapper-filter .filter-text {
  display: flex;
  align-items: center;
  margin: auto 10px auto 30px;
  padding-left: 20px;
  min-width: 30px;
} */

/* .customer__maintain-wrapper .filter-container .MuiInputAdornment-positionEnd {
  margin: unset;
}
.customer__maintain-wrapper .filter-container .calendar-item .MuiFormControl-root {
  position: relative;
}
.customer__maintain-wrapper .filter-container .calendar-item .MuiInputBase-root {
  position: absolute;
}
.customer__maintain-wrapper .filter-container .calendar-item .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  margin: 0 10px;
  top: 26px;
  position: absolute;
} */

.customer__maintain-wrapper .select-item .select-container .MuiOutlinedInput-input,
.customer__maintain-wrapper .sorted-select .sorted-container .MuiOutlinedInput-input {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* .customer__container .search-item .MuiFormControl-root .MuiInputBase-root input {
  min-width: 222px;
} */

/* .customer__container .wrapper-filter .filter-text .toolbar-text {
  padding-right: 0;
} */
/* @media screen and (max-width: 768px) {
  .customer__maintain-wrapper .customer__container .toolbar-text {
    display: none;
  }
} */

/* .customer__maintain-wrapper .btn-download {
  width: 34%;
  height: 25px;
  display: flex;
  position: relative;
  margin-bottom: 15px;
  color: white;
  min-width: 100px;
  justify-content: flex-end;
} */
/* .btn-text {
  color: white;
  text-decoration: none;
  font-size: 12px;
}
.redirect-item-customer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
