.custom-checkbox {
  display: inline-block;
}

.custom-checkbox .MuiSvgIcon-root {
  margin: 0 auto;
}

.custom-checkbox .MuiSvgIcon-root {
  color: rgb(150, 150, 150);
}

.MuiIconButton-colorSecondary:hover {
  background-color: unset !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: unset !important;
}
.checkbox-compliance .MuiTouchRipple-root {
  display: none;
}
.custom-checkbox label {
  font-weight: 500;
  user-select: none;
  margin-bottom: 0;
  color: rgb(59, 56, 56);
  font-size: 12px;
  display: flex;
  align-items: center;
  min-width: 120px;
}

.custom-checkbox.checkbox-report input {
  position: absolute;
  left: -9999px;
}
.custom-box {
  position: relative;
}

.custom-box:before {
  position: absolute;
}

.custom-checkbox .MuiCheckbox-colorPrimary .MuiSvgIcon-root {
  margin: 0;
  width: 25px;
  height: 25px;
  color: rgb(85, 130, 50);
}

.custom-checkbox .Mui-checked .MuiSvgIcon-root {
  color: rgb(85, 130, 50) !important;
}
.MuiCheckbox-root {
  padding-left: unset !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(85, 130, 50) !important;
  padding-left: unset;
}
