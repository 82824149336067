.download-status__maintain-wrapper {
    width: 100%;
    display: block;
    /* overflow: auto; */
  }
  
  .download-status__maintain-wrapper .download-status__container {
    width: 100%;
  }
  .spacing {
    height: 40px;
  }