.id-data-container,
.id-folder-container,
.nu-await-container,
.nu-alert-container {
  display: flex;
  align-items: flex-end;
}

.nu-alert-container,
.nu-await-container {
  position: relative;
  width: 250px;
}
.minutes-container {
  position: absolute;
  font-size: 12px;
  right: 70px;
  top: 20px;
}

.default-template-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 12px;
}

.default-template-container .wrapper_select_input {
  min-width: fit-content;
}

/* .title-line {
  padding-right: 60px !important;
}

.title-line-output {
  padding-right: 60px !important;
} */

.title-line label,
.title-line-output label {
  min-width: 140px;
}

.customer-template-container {
  font-size: 12px;
  color: rgb(85, 130, 50);
  margin-bottom: 10px;
}

.output-table-container {
  display: flex;
  flex-direction: column;
  margin: 20px 37px;
  width: 700px;
}
.table-container {
  width: 100%;
}
.output-table-add {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.add-account-template {
  cursor: pointer;
}

.modal-account-template {
  min-width: 600px;
}

.modal-account-template .MuiDialog-paperWidthSm {
  width: 600px;
  height: 300px;
}

.button-modal-account {
  display: flex;
  justify-content: flex-end;
}

.button-modal-account .clear-button {
  margin-right: 0px !important;
}
.form-account-regist-update .MuiFormLabel-root.Mui-disabled,
.form-account-user-ruduser .MuiFormLabel-root.Mui-disabled {
  color: rgb(150, 150, 150) !important;
}

.form-template-container .wrapper_select_input {
  width: 100%;
}

.form-template-container {
  margin-top: 10px;
}

.form-template-icon {
}

.form-template-container .MuiGrid-align-items-xs-flex-end {
  margin-left: 10px;
}

.form-template-container {
  display: flex;
  height: 48px;
}

.form-template-icon {
  width: 28.2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
}

.form-account-template .form-account-cd-cust .wrap-icon svg {
  font-size: 24px;
}

.form-template-container .form-template-select .wrapper_select_input #label {
  left: 10px;
}
.form-account-cd-cust {
  display: flex;
  align-items: flex-end;
}
.form-account-template .nm-cust-container {
  padding: 0 0 5px 20px;
  font-size: 12px;
}
.form-account-template .form-account-cd-cust .width200 {
  width: 200px;
}
.modal-account-title-text {
  font-size: 15px;
}
.modal-account-input-text {
  font-size: 12px;
}
.iconDeleteTemplate {
  position: absolute;
  right: -40px;
  top: 5px;
}

/* custom css with custom class name  */

/* custom style with custom style class name  */
.hidden-input .width300{
  display: none;
}
.no-border-input .Mui-disabled.MuiInputBase-formControl.MuiInput-formControl:before{
  border-bottom: none !important;
}
.hidden-input .info-container{
  margin-left: 20px;
}